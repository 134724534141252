import React, { useState, useEffect } from 'react';
import './Hero.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShoppingCart, faBoxOpen, faChartLine, faFilePdf, faHandshake } from '@fortawesome/free-solid-svg-icons';
import '@dotlottie/player-component/dist/dotlottie-player.mjs';


const Hero = () => {


    const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowAnimation(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);


  return (
    <section className="hero">
        
      <div className="col-12 hcenter logo-container">
        <img src="/logo512.png" className="logoHero rotationLogo" alt="Logo Feliz Trade" />
        <h2 className="responsive-headline">FELIZ TRADE</h2>
        <p className="mb-5"> Specjalizujemy się w nowoczesnych technologiach i automatyzacji, pomagając firmom działać szybciej i sprawniej. Nasze rozwiązania wspierają codzienną pracę przedsiębiorców, oszczędzając czas i redukując koszty. </p>  
        <p className="mb-5"> Stworzymy dla Ciebie nowoczesną stronę internetową i aplikację, które będą w pełni kompatybilne i zautomatyzowane. Nasze rozwiązania są dostosowane do indywidualnych potrzeb każdego klienta, aby wspierać Twój biznes w codziennej pracy i zwiększać jego efektywność.</p> 
        <i className="bounce fa-solid fa-arrow-down"></i>
      </div>
        <div class="col-12 pt-5 buttons-section">
            <div class="row justify-content-center g-2">
                <div class="col-6 col-md-3">
                    <a class="btn btn-outline-light w-100 text-center" href="https://gamma.app/docs/Nowoczesne-Rozwiazania-dla-Restauracji-hhu1dfoq1ju24og" target="_blank">
                        <FontAwesomeIcon icon={faBoxOpen} /> OFERTA
                    </a>
                </div>
                <div class="col-6 col-md-3">
                    <a class="btn btn-outline-light w-100 text-center" href="http://feliztradeltd.com/files/Aplikacja_Restauracje.pdf" target="_blank">
                        <FontAwesomeIcon icon={faFilePdf} /> OPIS PRODUKTU
                    </a>
                </div>
                <div class="col-6 col-md-3">
                    <a class="btn btn-outline-light w-100 text-center" href="mailto:FelizTradeLTD@proton.me" target="_blank">
                        <FontAwesomeIcon icon={faHandshake} /> KONTAKT
                    </a>
                </div>
                <div class="col-6 col-md-3">
                    <a class="btn btn-outline-light w-100 text-center" href="https://restaurantfeliz-demo.onrender.com/menu/2" target="_blank">
                        <FontAwesomeIcon icon={faChartLine} /> DEMO
                    </a>
                </div>
            </div>
        </div>
          
                <p>V2 website coming soon!</p>
                <div className="animation-container">
                    <dotlottie-player className={`animation-bg ${showAnimation ? 'show' : ''}`} src="https://lottie.host/5c96f2cc-24c6-43cb-a67f-e6b77ba83238/w6ZY0WiawE.json" background="transparent" speed="1" style={{ width: '300px', height: '300px' }} loop autoplay></dotlottie-player>
                </div>
    </section>
  );
}

export default Hero;
