import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header>
      <img src="logo512.png" className="logoHeader" alt="Logo Feliztrade" />
      <h1>FELIZ TRADE LTD</h1>
    </header>
  );
}

export default Header;
