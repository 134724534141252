import React from 'react';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Uni from './components/Uni/Uni';
import './App.css';

function App() {
  return (
    <div className="App">
      <Uni />
      <Header />
      <Hero />
    </div>
  );
}

export default App;
